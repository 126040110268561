import React from 'react';
import SEO from '../../components/seo';
import AuthorPageEN from '../../components/desktop/AuthorPage/AuthorPageEN';

const Artist = () => {
  return (
    <>
      <SEO title={'Biography'} />
      <AuthorPageEN />
    </>
  );
};

export default Artist;
