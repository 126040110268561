import React from 'react';
import {
  ColorWrapper,
  Container,
  GalleryColumnTwo,
  ImageMove,
  PageContainer,
  PhotoWithSign,
  TextSpaceMargin,
  PhotoSign,
  GridColorWrapper,
  GridLeft,
  GridImage,
  ImageTitle,
  GridTitle,
  CenterList,
  EventBlock,
  Date,
  EventName,
  SourceText,
  MobileColorWrapper,
} from './styles';
import moving from './images/01.png';
import {SectionText, SectionTitle} from '../../atoms';
import {TitlePage} from '../PartnersPage/styles';
import Gallery from '../Gallery/Gallery';
import img1 from './images/Jan Szczepkowski w czasacj krakowskich, ok. 1900-1914.jpg';
import img2 from './images/Jan Szczepkowski w mundurze polskim.jpg';
import img3 from './images/Maria i Jan na ławce na świeżo wytyczonej ulicy w Milanówku.jpg';
import img4 from './images/Jan Szczepkowski razem z żoną Marią.jpg';
import img5 from './images/Jan Szczepkowski w milanowskim ogrodzie.jpg';
import img6 from './images/Jan Szczepkowski przy stole kreślarskim (1936r.) NAC.jpg';
import img6_2 from './images/Jan Szczepkowski odtwarza model pomnika Adama Mickiewicza w Warszawie w pracowni Przedsiębiorstwa Sztuk Pięknych.jpg';
import img7 from './images/Nekrologi artysty w Życiu Warszawy  Nr 45 (6326) piątek, 21 lutego, 1964 r..jpg';
import img8 from './images/IMG_7544.jpg';
import img9 from './images/Jan Szczepkowski (z prawej) z architektem Kazimierzem Skórewiczem (w fotelu) na podwórzu gmachu Sejmu.jpg';
import img10 from './images/Jan Szczepkowski w gronie kolegów (1914 r.).jpg';
import img11 from './images/Jan Szczepkowski wraz z żoną Marią i teściową Walerią Morozowicz zaraz po powrocie z podróży poślubnej do Włoch.jpg';
import img12 from './images/Szopka Zielonego Balonika (ok. 1908 r.) Polona kopia.jpg';
import img13 from './images/Aniołek lub pastuszek adoracyjny.jpg';
import img14 from './images/Anioł z trąbką NAC.jpg';
import img15 from './images/Anioł ze skrzypcami NAC.jpg';
import img16 from './images/Odlew postaci Wojciecha Bogusławskiego (1936 r.) NAC.jpg';
import img17 from './images/Maska pośmiertna Marszałka Józefa Piłsudskiego 1935 r. NAC.jpg';
import img18 from './images/Projekt konkursowy autorstwa artysty rzeźbiarza Jana Szczepkowskiego na pomnik marszałka Józefa Piłsudskiego na placu Na Rozdrożu w Warszawie.jpg';
import img19 from './images/Projekt konkursowy autorstwa artysty rzeźbiarza Jana Szczepkowskiego, który otrzymał I nagrodę, 1926r. NAC.jpg';
import img20 from './images/Projekt pomnika Wojciecha Bogusławskiego NAC.jpg';
import img21 from './images/Afisz zapraszający na zabawę taneczna organizowaną przez Zielony Balonik.jpg';
import img22 from './images/Projekt jednego z licznych cmentarzy wojennych wykonany w okresie pracy w Kriegsgräber-Abteilung .jpg';
import img23 from './images/Karykatura pisarza Benedykta Hertza. Z tyłu dopisek _Benedykt Hertz w _urżniętym widzie_..jpg';
import img24 from './images/Poeta Zdzisław Kleszczyński i malarz Konstanty Mackiewicz nasatyrycznym rysunki Jana Szczepkowskiego, 1926 r..jpg';
import PlayerGrey from '../../atoms/PlayerGrey/PlayerGrey';

const AuthorPage = () => {
  return (
    <PageContainer backgroundColor={'#a3c0c0'} style={{paddingBottom: 0}}>
      <Container>
        <ImageMove src={moving} />
        <TextSpaceMargin>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <SectionTitle color={'#525252'} jan={true}>
              JAN SZCZEPKOWSKI
            </SectionTitle>
            <PlayerGrey src={require('../../../audio/jan_szczepowski.mp3')} />
          </div>
          <SectionText color={'#525252'} style={{paddingTop: '1.5rem'}}>
            &emsp;&emsp;Jan Szczepkowski was born on 8 March 1878 in Stanisławów
            in Galicia as a son of Alojzy Szczepkowski (1839-1916), who was a
            railway clerk, and Józefa from Kuczyńscy family(1840-1891). He was
            brought up in a family that cherished patriotic values. In his
            memory notes he often emphasizes the fact that his mother, at the
            age of 13, was involved in helping insurgents fighting in the
            January Uprising in Lwów.
            <br />
            <br />
            &emsp;&emsp;In 1891, he started to learn the art of ornamental
            sculpting in Imperial-Royal Vocational School of Wood Craft.
            According to Katarzyna Chruździńska-Uhera, an artist researching
            Szczepkowski’s life, that period of time had the biggest impact on
            shaping his style presented in the 20s. Truth to be told, he was not
            particularly fond of these years although he did admit that it was
            the moment of his first encounter with Podhale and folk art. At
            school, he learned practical woodworking techniques which proved to
            be useful later on.
            <Gallery
              maxWidth={'60'}
              columnsNumber={2}
              isImgFit
              hoverColor="rgba(163, 192, 192, 0.4)"
              colorSign="#525252"
              images={[
                {
                  src: img1,
                  sign: 'Jan Szczepkowski w czasach krakowskich, ok. 1900-1914',
                },
                {
                  src: img2,
                  sign: 'Jan Szczepkowski w mundurze polskim',
                },
              ]}
            />
            &emsp;&emsp;At last, he finished his education in Academy of Fine
            Arts in Cracow where he studied sculpture under the supervision of
            Alfred Daun and Konstanty Laszczka. <br />
            <br />
            &emsp;&emsp;In the meantime he was working odd jobs such as making
            ornaments in stucco workshops or painting portraits. Characteristic
            styles for this particular period are realism combined with Lesser
            Poland symbolism and influence of Rodin.
            <br />
            <br />
            &emsp;&emsp;In 1900 Szczepkowski was offered a post of an art
            director in the office of Eng. Arch. Florian Wyganowski. It gave him
            an opportunity to work on the Jubilee Exhibition for the 700th
            anniversary of Riga. His stay there was interrupted by military
            draft notice from Austrian army ordering him to show up in Cracow.
            It was so-called one-year volunteer service which ended with an exam
            and a transfer to the reserve. In subsequent years Szczepkowski took
            up a job in Girl’s School of Fine Arts and Applied Arts. In 1902, he
            became an art director of the majolica department of “Józef
            Niedźwiedzicki i Spółka '' faience factory in Dębniki near Cracow.
            <br />
            <br />
            &emsp;&emsp;He got the annual grant of Czartoryscy in 1907 and went
            to Paris. The scholarship was extended for two years thanks to
            Auguste Rodin’s support. Rodin had an enormous influence on Jan
            Szczepkowski and although our artist wasn’t his apprentice, he knew
            Rodin and admired his artwork. He also cherished Emil A. Bourdell
            but their relationship was more of a friendship rather than
            master-apprentice situation.
          </SectionText>
        </TextSpaceMargin>
      </Container>
      <ColorWrapper
        bgColor={'#525252'}
        style={{marginTop: '3rem', paddingBottom: '2rem'}}>
        <Container>
          <Gallery
            maxWidth={'60'}
            columnsNumber={2}
            isImgFit
            hoverColor="rgba(163, 192, 192, 0.4)"
            colorSign="#fbfbfb"
            images={[
              {
                src: img3,
                sign:
                  'Maria i Jan na ławce na świeżo wytyczonej ulicy w Milanówku',
              },
              {
                src: img4,
                sign: 'Jan Szczepkowski razem z żoną Marią',
              },
            ]}
          />
          <SectionText color={'#fbfbfb'}>
            &emsp;&emsp;Later on, he returned to Cracow and his social life.
            Back then he met a promising actress Maria Morozowicz: a daughter of
            a well-known actor Rufin Morozowicz. Their friendship quickly
            flourished into something more. The lovebirds got married on 18th
            May 1913 in Bernardine church in Cracow. They chose Xawery
            Dunikowski (sculptor) and Albin Chęciński (lawyer) for wedding
            witnesses. The couple spent their honeymoon in Italy. In 1914, he
            traveled to Bulgaria as a judge in a contest for a monument of
            Bulgarian national hero Hadzhi Dimitar. In the same year he got
            another military call from Austro-Hungarian Army, leaving his
            pregnant wife in Cracow. A little later, as he got severely wounded
            (probably on 3 May 1915 in the battle of Kraśnik), he got assigned
            to Kriegsgräber-Abteilung: a branch taking care or building military
            cemeteries. In 1916 the artist’s father passed away. When the war
            was reaching its end he escorted his daughter Hanna and his wife to
            in-laws house in Milanówek. At the end of 1918 Szczepkowski was
            discharged from the army. In July 1920 he joined the Polish Army as
            a volunteer. He did not spend much time there though as in December
            the same year the volunteer officers got demobilized.
            <br />
            <br />
            &emsp;&emsp;The biggest progress he made art-wise and career-wise
            happened in the 20’ and 30’. In 1925, which is claimed to be the
            most crucial year when it comes to his creative activity, he made a
            hit on the International Exhibition of Decorative Arts and Modern
            Industry in Paris. It happened mostly thanks to General Polish
            Representative of the Exhibition Jerzy Warchałowski. As he was
            selecting artists seeking and developing national Polish style who
            represented the aesthetics closest to his heart, 172 awards were
            given to Polish creators. Among the greatest successes of the
            exhibition was the purchase of Szczepkowski's Chapel of Nativity by
            the French Government. Our artist was awarded with the Grand Prix
            and Legion of Honour. It was the start of the busiest period in his
            career. During that time he made decorative bas-relief frieze for
            the conference room’s rotunda in the Seym of the Republic of Poland
            (1927-1928) or frieze for Ateneum Theatre (1929) that was destroyed
            in 1939.
            <Gallery
              style={{marginBottom: '2rem', gridGap: '2rem 1rem'}}
              columnsNumber={3}
              isImgFit
              hoverColor="rgba(163, 192, 192, 0.4)"
              colorSign="#fbfbfb"
              images={[
                {
                  src: img5,
                  sign: 'Jan Szczepkowski w milanowskim ogrodzie',
                },
                {
                  src: img6,
                  sign: 'Jan Szczepkowski przy stole kreślarskim (1936r.) NAC',
                },
                {
                  src: img6_2,
                  sign:
                    'Jan Szczepkowski odtwarza model pomnika Adama Mickiewicza w Warszawie w pracowni Przedsiębiorstwa Sztuk Pięknych',
                },
              ]}
            />
            &emsp;&emsp;Szczepkowski spent the second world war in Milanówek
            where he moved with his family in 1936 after his dismissal from the
            post of the Warsaw’s Urban School of Decorative Arts principal. At
            the time his daughter and son-in-law were working for intelligence
            of the Home Army and fought in the Warsaw Uprising. After the fall
            of the uprising many people found shelter in “Waleria” villa (later
            it was even occupied by the enemy for a while). Fortunately, the
            whole family survived this difficult period. Despite the war,
            Szczepkowski was still working intensively. In the 40’ he was
            preparing plans for the reconstruction of demolished Warsaw. He was
            sculpting portraits of his loved ones. He was participating in
            activities leading to restoring the cultural life in Poland.
            <br />
            <br />
            &emsp;&emsp;On 1 February 1945 Warsaw’s Department of Trade Union of
            Polish Artists was appointed in Milanówek and Szczepkowski became a
            member of its Qualifying Committee. The organization was working
            effectively and arranged an exhibition of local artists including
            our sculptor. In 1947, he became the first leader of the Institute
            Council supervising the activity of the Central Institute of
            Culture. At the opening conference celebrating the beginning of his
            work there, he gave a speech in which he shared his vision. He
            essentially said he had wanted to disseminate art and culture along
            with giving them a new proper meaning. Furthermore, he wished that
            every single person living in the real democratic Republic of Poland
            would be able to experience their beauty along with the meaning they
            were bound with. In 1957, as a member of the Association of Polish
            Artists and Designers General Management, he took part in an outdoor
            meeting of the Council for the Protection of Struggle and Martyrdom
            Sites. In July 1958 he and other 22 artists and social activists
            formed Temporary Organizational Council for Society of Friends of
            Fine Arts in Warsaw.
            <Gallery
              maxWidth={'60'}
              columnsNumber={2}
              isImgFit
              hoverColor="rgba(163, 192, 192, 0.4)"
              colorSign="#fbfbfb"
              images={[
                {
                  src: img7,
                  sign:
                    'Nekrologi artysty w Życiu Warszawy  Nr 45 (6326) piątek, 21 lutego, 1964 r.',
                },
                {
                  src: img8,
                  sign:
                    'Grób Jana Szczepkowskiego na cmentarzu powązkowskim w Warszawie',
                },
              ]}
            />
            &emsp;&emsp;Jan Szczepkowski died on 17 February 1964 at the age of
            85. He was buried on 21 February in Powązki Cemetery in Warsaw
            specifically in “Avenue of the Distinguished” (1st raw, 28th place).
            The tombstone on his grave was created by Zofia and Karol Tchorek.
          </SectionText>
        </Container>
        <Container style={{marginTop: '2rem'}}>
          <p
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#ffffff',
            }}>
            Information Sources:
            <br /> <br />
            Bartoszek Monika, Jan Szczepkowski, Centrum Rzeźby Polskiej w
            Orońsku, Orońsko, 2014
            <br /> <br />
            Chrudzimska – Uhera Katarzyna, JAN SZCZEPKOWSKI, ŻYCIE I TWÓRCZOŚĆ,
            Urząd Miejski w Milanówku, 2008 r.
            <br /> <br />
            Chrudzimska-Uhera Katarzyna, Małżeństwo z rozsądku? : czyli o
            artystycznych dylematach i kompromisach w czasach władzy ludowej, w
            oparciu o działalność społeczną, wypowiedzi i projekty rzeźbiarskie
            Jana Szczepkowskiego z lat 1945-1964, Saeculum Christianum : pismo
            historyczne
            <br /> <br />
            Chrudzimska-Uhera Katarzyna, O rzeźbiarzu (nieco) zapomnianym,
            Niepodległość i Pamięć 17/1 (31), 257-271, 2010
            <br /> <br />
            Chrudzimska-Uhera Katarzyna, Prof. Jan Szczepkowski i jego dzieło
            (1878-1964), Milanówek Miejsce Magiczne pod red. Andrzeja Pettyna,
            Towarzystwo Miłośników Milanówka, 2005
            <br /> <br />
            Szczepkowski Jan, Wypukłe i wklęsłe. Wspomnienia, Wstęp, opracowanie
            i komentarz Katarzyna Chrudzimska - Uhera, Urząd Miasta Milanówka,
            Milanówek, 2010
          </p>
        </Container>
      </ColorWrapper>
      <GridColorWrapper bgColor={'#a3c0c0'}>
        <GridLeft></GridLeft>
        <GridTitle>
          <SectionTitle color={'#525252'} Kalendarium>
            Calendar
          </SectionTitle>
          <CenterList>
            <EventBlock>
              <Date>1878, 8 March</Date>
              <EventName>birthday in Stanisławów</EventName>
            </EventBlock>
            <EventBlock>
              <Date>18.09.1891- 13.07.1895</Date>
              <EventName>
                education in Imperial-Royal Vocational School of Wood Craft
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1891</Date>
              <EventName>mother’s death</EventName>
            </EventBlock>
            <EventBlock>
              <Date>1895-1900</Date>
              <EventName>education in Academy of Fine Arts in Cracow</EventName>
            </EventBlock>
            <EventBlock>
              <Date>1901</Date>
              <EventName>
                starting work as an art director in the office of Eng. Arch.
                Florian Wyganowski in Riga
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1901/1902</Date>
              <EventName>
                mandatory military service in imperial-royal Austrian army
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1901</Date>
              <EventName>
                starting work in Girl’s School of Fine Arts and Applied Arts in
                Cracow
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1902-1905</Date>
              <EventName>
                becoming an art director of majolica department of “Józef
                Niedźwiedzicki i Spółka” faience factory in Dębniki near Cracow
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1907-1908</Date>
              <EventName>
                receiving the annual grant of Czartoryscy and travelling to
                Paris
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1913, 18 May</Date>
              <EventName>
                marriage of Szczepkowski and Maria Mrozowicz
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1914, July</Date>
              <EventName>
                traveling to Bulgaria as a judge for the Hadzhi Dimitar monument
                contest
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1914-1918</Date>
              <EventName>
                World War I, military service in imperial-royal Austrian army,
                being assigned to Kriegsgräber-Abteilung – a branch taking care
                or building military cemeteries, service in Station Command of
                Prisoners’ Camp in Wadowice
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1916</Date>
              <EventName>father’s death</EventName>
            </EventBlock>
            <EventBlock>
              <Date>1920</Date>
              <EventName>voluntary service in Polish Army</EventName>
            </EventBlock>
            <EventBlock>
              <Date>Circa 1921-1936</Date>
              <EventName>
                work as a pedagogue, from 1923 as a principal in Urban School of
                Decorative Arts and Painting in Warsaw
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1925</Date>
              <EventName>
                receiving Grand Prix of International Exhibition of Decorative
                Arts and Industry in Paris
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1939-1945</Date>
              <EventName>stay in Milanówek</EventName>
            </EventBlock>
            <EventBlock>
              <Date>from 1945</Date>
              <EventName>
                Szczepkowski contributes to work of Trade Union of Polish
                Artists and leads the Council of Central Institute of Culture
              </EventName>
            </EventBlock>
            <EventBlock>
              <Date>1964, 17 February</Date>
              <EventName>death of Jan Szczepkowski</EventName>
            </EventBlock>
          </CenterList>
        </GridTitle>
        <GridImage>
          <ImageTitle>Autoportret, 1960</ImageTitle>
          <SourceText>
            Creation of the Calendar was based on memories of Jan Szczepkowski
            included in the book “Wypukłe i wklęsłe” (eng. “Convex and concave”)
            elaborated by Katarzyna Chrudzimska–Uhera.
          </SourceText>
        </GridImage>
        <div />
      </GridColorWrapper>
      <MobileColorWrapper>
        <Container>
          <p
            style={{
              fontFamily: 'LATOREGULAR',
              fontSize: '10px',
              lineHeight: 1.2,
              textAlign: 'justify',
              color: '#525252',
            }}>
            Creation of the Calendar was based on memories of Jan Szczepkowski
            included in the book “Wypukłe i wklęsłe” (eng. “Convex and concave”)
            elaborated by Katarzyna Chrudzimska–Uhera.
          </p>
        </Container>
      </MobileColorWrapper>
      <ColorWrapper bgColor={'#525252'} style={{paddingBottom: '2rem'}}>
        >
        <Container style={{zIndex: 99}}>
          <Gallery
            style={{gridGap: '3rem 1rem'}}
            columnsNumber={4}
            isImgFit
            hoverColor="rgba(163, 192, 192, 0.4)"
            colorSign="#ffffff"
            images={[
              {
                src: img9,
                sign:
                  'Jan Szczepkowski (z prawej) z architektem Kazimierzem Skórewiczem (w fotelu) na podwórzu gmachu Sejmu',
              },
              {
                src: img10,
                sign: 'Jan Szczepkowski w gronie kolegów (1914 r.)',
              },
              {
                src: img11,
                sign:
                  'Jan Szczepkowski wraz z żoną Marią i teściową Walerią Morozowicz zaraz po powrocie z podróży poślubnej do Włoch',
              },
              {
                src: img12,
                sign: 'Szopka Zielonego Balonika (ok. 1908 r.) Polona',
              },
              {
                src: img14,
                sign: 'Anioł z trąbką NAC',
              },
              {
                src: img15,
                sign: 'Anioł ze skrzypcami NAC',
              },
              {
                src: img20,
                sign: 'Projekt pomnika Wojciecha Bogusławskiego NAC',
              },

              {
                src: img16,
                sign: 'Odlew postaci Wojciecha Bogusławskiego (1936 r.) NAC',
              },
              {
                src: img17,
                sign:
                  'Maska pośmiertna Marszałka Józefa Piłsudskiego 1935 r. NAC',
              },
              {
                src: img18,
                sign:
                  'Projekt konkursowy autorstwa artysty rzeźbiarza Jana Szczepkowskiego na pomnik marszałka Józefa Piłsudskiego na placu Na Rozdrożu w Warszawie',
              },
              {
                src: img19,
                sign:
                  'Projekt konkursowy autorstwa artysty rzeźbiarza Jana Szczepkowskiego, który otrzymał I nagrodę, 1926r. NAC',
              },
              {
                src: img13,
                sign: 'Aniołek lub pastuszek adoracyjny',
              },
              {
                src: img21,
                sign:
                  'Afisz zapraszający na zabawę taneczna organizowaną przez Zielony Balonik',
              },
              {
                src: img22,
                sign:
                  'Projekt jednego z licznych cmentarzy wojennych wykonany w okresie pracy w Kriegsgräber-Abteilung',
              },
              {
                src: img23,
                sign:
                  'Karykatura pisarza Benedykta Hertza. Z tyłu dopisek Benedykt Hertz w urżniętym widzie',
              },
              {
                src: img24,
                sign:
                  'Poeta Zdzisław Kleszczyński i malarz Konstanty Mackiewicz na satyrycznym rysunki Jana Szczepkowskiego, 1926 r.',
              },
            ]}
          />
        </Container>
      </ColorWrapper>
      <ColorWrapper bgColor={'#a3c0c0'}>
        <Container>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/uOwF-d-CCUo?controls=0"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </Container>
      </ColorWrapper>
    </PageContainer>
  );
};

export default AuthorPage;
